import { __decorate } from "tslib";
// Watch
import { Component, Vue, Prop } from 'vue-property-decorator';
import { financeiroParcelaStore, analyticsStore, userToolStore, financeiroParcelaMovimentacaoStore, mainStore } from '@/store';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import FinanceiroValorPagoTextComponent from '@/components/FinanceiroValorPagoTextComponent.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import SaveButton from '@/components/SaveButton.vue';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                show: true,
                table: 'financeiro_categoria',
                text: 'Categoria',
                type: '',
                value: 'financeiro_categoria_nome',
                field: 'nome',
            },
            {
                show: true,
                table: 'tmp',
                text: 'Entidade - Nome',
                value: 'entidade_nome',
            },
            {
                show: true,
                table: 'financeiro',
                text: 'Descrição',
                type: '',
                value: 'financeiro_observacao',
                field: 'observacao',
            },
            {
                show: true,
                table: 'tmp',
                text: 'Entidade - Tipo',
                value: 'entidade_tipo',
            },
            {
                show: false,
                table: 'aluno',
                text: 'Aluno - Cpf',
                value: 'aluno_cpf',
            },
            {
                show: false,
                table: 'aluno',
                text: 'Aluno - Nome do resposável',
                value: 'responsavel_nome',
            },
            {
                show: false,
                table: 'aluno',
                text: 'Aluno - Cpf do resposável',
                value: 'responsavel_cpf',
            },
            {
                show: false,
                table: 'aluno',
                text: 'Aluno - Endereço',
                value: 'aluno_rua',
            },
            {
                show: false,
                table: 'aluno',
                text: 'Aluno - Bairro',
                value: 'aluno_bairro',
            },
            {
                show: false,
                table: 'aluno',
                text: 'Aluno - Número',
                value: 'aluno_numero',
            },
            {
                show: false,
                table: 'aluno',
                text: 'Aluno - Contato',
                value: 'aluno_contatos',
            },
            {
                show: true,
                table: 'tmp',
                text: 'Parcela',
                type: '',
                value: 'parcela',
            },
            {
                show: false,
                table: 'financeiro',
                text: 'Emissão',
                type: '',
                value: 'data_emissao',
            },
            {
                show: true,
                table: 'financeiro_parcela',
                text: 'Vencimento',
                type: '',
                value: 'vencimento',
            },
            {
                show: true,
                table: 'financeiro_parcela_movimentacao',
                text: 'Recebimento',
                type: '',
                value: 'data_pagamento'
            },
            {
                show: false,
                table: 'financeiro_parcela_movimentacao',
                text: 'Observação',
                type: '',
                value: 'financeiro_parcela_movimentacao_observacao',
                field: 'observacao',
            },
            {
                show: false,
                table: 'financeiro_parcela',
                text: 'Valor Integral',
                type: '',
                value: 'valor_integral',
            },
            ...(this.currentUser.company.escola_id === 1 ? [{
                    show: false,
                    table: 'financeiro_parcela',
                    text: 'Desconto Club',
                    type: '',
                    value: 'valor_desconto',
                },] : []),
            {
                show: false,
                table: 'tmp',
                text: 'Valor com desconto',
                type: '',
                value: 'valor_com_desconto',
            },
            {
                show: false,
                table: 'tmp',
                text: 'Valor Atualizado',
                type: '',
                value: 'valor_atualizado',
            },
            {
                show: true,
                table: 'tmp',
                text: 'Valor Pago',
                type: '',
                value: 'valor_pago',
            },
            {
                show: true,
                table: 'financeiro_parcela',
                text: 'Total Pago',
                type: '',
                value: 'total_pago',
                field: 'valor_pago'
            },
            {
                show: false,
                table: 'financeiro_parcela',
                text: 'Observação',
                type: '',
                value: 'observacao',
            },
            {
                show: false,
                table: 'forma_pagamento',
                text: 'Forma de pagamento',
                type: '',
                value: 'forma_de_pagamento',
                field: 'id'
            },
            {
                show: true,
                table: 'financeiro_parcela_status',
                text: 'Status da parcela',
                type: '',
                value: 'financeiro_parcela_status_nome',
                field: 'nome',
            },
            {
                show: true,
                table: 'contrato',
                text: 'Status do contrato',
                type: '',
                value: 'contrato_status_id',
                field: 'status_id',
            },
            {
                show: true,
                table: 'caixa',
                text: 'Caixa',
                type: '',
                value: 'caixa_id',
                field: 'id',
            },
            {
                show: true,
                text: 'Ações',
                value: 'actions',
            },
        ];
        this.selectPeriodDate = null;
    }
    get allowView() {
        if (userToolStore.userToolsIndexed['Contas a receber']) {
            return userToolStore.userToolsIndexed['Contas a receber'][0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed['Contas a receber']) {
            return userToolStore.userToolsIndexed['Contas a receber'][0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed['Contas a receber']) {
            return userToolStore.userToolsIndexed['Contas a receber'][0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed['Contas a receber']) {
            return userToolStore.userToolsIndexed['Contas a receber'][0].allow_delete;
        }
    }
    get totals() {
        return analyticsStore.totals;
    }
    get currentUser() {
        return mainStore.userProfile;
    }
    getFinanceiroValorPagoTextObj(item) {
        return {
            financeiro_parcela_status_id: item.financeiro_parcela_status_id,
            valor_pago: item.total_pago,
            valor_original: item.valor_com_desconto,
        };
    }
    async buscar() {
        const filters = {};
        filters['financeiro.tipo'] = {
            type: 'int',
            value: 0,
        };
        filters['tmp.data_pagamento'] = {
            type: 'timestamp',
            value: [this.selectPeriodDate[0], this.selectPeriodDate[1]],
        };
        analyticsStore.setDefaultFilters(filters);
        await this.doSearchTotals();
        await analyticsStore.executeSearchItems();
        // @ts-ignore
        await this.$refs.loadingButton.changeLoadingStatus(false);
    }
    async doSearchItems(queryIn) {
        return await financeiroParcelaStore.getFinanceiroParcelasQuery(queryIn);
    }
    async doSearchTotals() {
        await analyticsStore.getAnalyticsFinanceiroParcelaMovimentacao({
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1],
            tipo: 0,
        });
    }
    routeEdit(id) {
        const rota = this.$router.resolve({
            name: 'main-contasareceber-edit',
            params: { id },
        });
        window.open(rota.href, '_blank');
    }
    async mounted() {
        analyticsStore.initAnalytics();
        analyticsStore.setFnSearchItems(financeiroParcelaMovimentacaoStore.getFinanceiroParcelaMovimentacaoQuery);
        analyticsStore.setHeaders(this.headers);
        // tela que tem defaultFilter nao precisa ter o buscar() aqui
    }
};
__decorate([
    Prop(Boolean)
], List.prototype, "financeiroType", void 0);
List = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
            EntidadeLinkComponent,
            SearchEntidadeComponent,
            FinanceiroValorPagoTextComponent,
            GenericListTotalCardComponent,
            GenericListItemsCardComponent,
            TextExpandableComponent,
            OpenNewTabComponent,
            SaveButton,
        },
    })
], List);
export default List;
